@import "themes/generated/my_variables.base.scss";

html, body {
  margin: 0;
  min-height: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
}

.content {
  line-height: 1.5;
  flex-grow: 1;

  h2 {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.container {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.layout-body {
  flex: 1;
  min-height: 0;
}

.side-nav-outer-toolbar .dx-drawer {
  height: calc(100% - 56px)
}

.content-block {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 20px;

  .screen-x-small & {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.responsive-paddings {
  padding: 20px;

  .screen-large & {
    padding: 40px;
  }
}

.dx-card.wide-card {
  border-radius: 0;
  margin-left: 0;
  margin-right: 0;
  border-right: 0;
  border-left: 0;
}

.with-footer > .dx-scrollable-wrapper >
.dx-scrollable-container > .dx-scrollable-content {
  height: 100%;

  & > .dx-scrollview-content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
}

$side-panel-min-width: 60px;


/* padding for header set to 0 */
::ng-deep .dx-datagrid-header-panel {
  padding: 0;
}

//.grid-container .dx-button {
//  border-radius: 4px;
//  padding: 8px 16px;
//  font-weight: 500;
//}

/*change the headers of the datagird */
::ng-deep .dx-header-row {
  background-color: $base-accent;
}

::ng-deep .dx-toast-message {
  padding: 0;
  color: black;
}

/* change the text in the headers*/

//::ng-deep .dx-datagrid-headers .dx-datagrid-text-content {
//  font-weight: bold;
//}

//.dx-datagrid-rowsview .dx-row:hover {
//  background-color: #f0f0f0
//}
//
//.dx-datagrid-rowsview .dx-row-alt {
//  background-color: #f0f0f0
//}

/* change the color of the selected row */
//.dx-datagrid-rowsview .dx-row.dx-data-row.dx-row-focused {
//  background-color: #e0e0e0;
//}
//
//.dx-datagrid-rowsview .dx-row.dx-data-row.dx-row-focused > td {
//  background-color: inherit !important;
//}

/*change the space between buttons*/
//.dx-datagrid-header-panel .dx-toolbar-before .dx-toolbar-item {
//  padding-right: 20px;
//  font-size: 20px;
//}

//.dx-toolbar .dx-toolbar-items-container {
//  height: 60px;
//}
//
//.checkboxes-mode {
//  position: absolute;
//  right: 20px;
//  bottom: 20px;
//}
//
//.option > .dx-selectbox {
//  width: 150px;
//  display: inline-block;
//  vertical-align: middle;
//}


::ng-deep .screen-large .responsive-paddings {
  padding: 20px
}
